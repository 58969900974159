<template>
  <div class="ibox" ref="emailConfiguration">
    <div class="ibox-title">
      <h4>Email</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-down"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content" style="display:none">
    

      <form id="config-email-form" name="config-email-form" role="form" action="#" method="post" @submit="onFormSubmit">

        <div class="form-group">
            <label for="accounts">Accounts</label>
            <textarea id="accounts" type="text" class="form-control" v-model="form.accounts" />
        </div>

        <button type="submit" class="btn btn-primary block full-width ladda-button" data-style="zoom-in">{{ $t('configuration.form.button') }}</button>

      </form>
    </div>
  </div>
</template>

<style scoped>
  #accounts {
    height: 200px;
    font-size: 12px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { enableIbox, disableIbox} from '../../../theme/inspinia-jquery'; 

@Component({
  components: { },
  
})
export default class Email extends Vue {

  @Prop({
    type: Object,
    required: true
  }) readonly config!: any

  form = {
    accounts : JSON.stringify(this.config.accounts, null, 2)
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=config-email-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);

    enableIbox(this.$refs.emailConfiguration as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.emailConfiguration as HTMLElement);
  }

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      ...this.form,
      accounts: JSON.parse(this.form.accounts)
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/config/email/update', input, options).then((response:any) => {
      
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>